/* eslint-disable no-shadow */
/* eslint-disable max-len */
/* eslint-disable react/jsx-indent */
/* eslint-disable import/no-mutable-exports */
/* eslint-disable import/no-cycle */
import './App.scss';
import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import { Route, Routes } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { LoadingBall } from '@ingka/loading';
// import { useDispatch } from 'react-redux';
import Header from './common-components/header/Header';
import Searchpage from './pages/Searchpage';
import Languagepopup from './common-components/languagePopup/Languagepopup';
import '@ingka/variables/style.scss';
import { USER_ID, USER_GROUP } from './Config';
import Editorpage from './pages/Editorpage';
import {
  gObjectTypeValues, gStatusTypeValues, gUserIdTypeValues, retrieveObjectType, retrieveStatus, retrieveUserId, retrieveUserdata, retrieveCategoryData,
  gCategoryDataValues,
} from './api/Apirequests';
import ErrorPage from './common-components/errorpage/Errorpage';
import Dashboardpage from './pages/Dashboardpage';
import SitsProvider from './context/SitsProvider';
import SitsContext from './context/SitsContext';
import BannerLanding from './common-components/landingbanner/BannerLanding';
// eslint-disable-next-line import/no-named-as-default
import BannerMessage from './common-components/landingbanner/BannerMessage';
import gDueinTypeValues from './components/startpage/data/dueinfilter.json';
// import { listofFilters } from './store/reducers/SearchpageReducers';
export let selectedPopupValue = [];
// intializing the selectedpopupvalue to capture the values from languagepopup

function App() {
  // const dispatch = useDispatch();
  const sitsContext = useContext(SitsContext);
  const [openPopup, setOpenPopup] = useState(true);
  // intializing the languagepopup and default it is true
  const [settingsClicked, setSettingsClicked] = useState();
  // eslint-disable-next-line no-unused-vars
  // intializing the settingsclicked to capture the click evnt of settings
  const [disableRelease, setDisableRelease] = useState(false);
  const [loadingOfreleasebutton, setLoadingOfreleasebutton] = useState(false);
  const [autoSaveError, setAutoSaveError] = useState(false);
  const [bannerMessageheight, setBannerMessageheight] = useState(0);
  const [windowResizing, setWindowResize] = useState(window.innerWidth);
  const [noLocaleFlag, setNoLocaleFlag] = useState(null);
  const auth = useAuth();
  const editorPageRef = useRef();

  useEffect(() => {
    // eslint-disable-next-line no-use-before-define
    window.addEventListener('resize', handleDashboardPageResize);
    return () => {
      // eslint-disable-next-line no-use-before-define
      window.removeEventListener('resize', handleDashboardPageResize);
    };
  }, []);
  const dataofFilters = (gObjectTypeValues, gStatusTypeValues, gUserIdTypeValues, gDueinTypeValues, gCategoryDataValues) => {
    // eslint-disable-next-line no-unused-vars
    const filtersData = {
      objecttypeFilters: gObjectTypeValues,
      statusFilters: gStatusTypeValues,
      useridFilters: gUserIdTypeValues,
      dueinFilters: gDueinTypeValues,
      categoryFilters: gCategoryDataValues,
    };
    // dispatch(listofFilters(filtersData));
  };

  const getUserPreferenceResponseCallbackHandler = (data) => {
    if (data.status === 500) {
      setNoLocaleFlag(true);
    } else {
      setNoLocaleFlag(false);
    }
  };

  useEffect(() => {
    if (auth.user !== undefined && auth.user.profile[USER_ID]) {
      retrieveUserdata(auth.user.profile[USER_ID], getUserPreferenceResponseCallbackHandler);
    }
  }, [auth.user]);

  // assigning the languagepopup values to selectedpopupvalue array
  const setLanguagePopupValueHandler = async (value) => {
    if (sitsContext.pageRedirection) {
      sitsContext.updatePageRedirection(false);
    }
    const languageCode = ((value.slice(0, 2)).toUpperCase());
    // The value is splitted to separate the lang_code and ctry_code
    const countryCode = ((value.slice(3, 5)).toUpperCase());
    const languageCodeForEN = (`${languageCode}(${countryCode})`);
    selectedPopupValue = {
      selectedlang: languageCode === 'EN' ? languageCodeForEN : languageCode,
      selectedlangvalue: value,
    };
    setOpenPopup(false);
    await retrieveObjectType();
    await retrieveCategoryData();
    await retrieveStatus();
    await retrieveUserId(value);
    dataofFilters(gObjectTypeValues, gStatusTypeValues, gUserIdTypeValues, gDueinTypeValues, gCategoryDataValues);
    // languagepopup is closed once the value is captured
  };

  const heightofBanner = (bannerheight) => {
    setBannerMessageheight(bannerheight);
  };

  // Function to disable/enable the Release button in the editor page
  const setReleaseDisableHandler = (disableFlag) => {
    setDisableRelease(disableFlag);
  };

  // Function to load the Release button  in the editor page
  const updateReleasebuttonloader = (enableflag) => {
    setLoadingOfreleasebutton(enableflag);
  };

  // Function to enable autosaveError flag to show the message in the header
  const updateAutoSaveErrorFlagHandler = (autoSaveErrorFlag) => {
    setAutoSaveError(autoSaveErrorFlag);
  };

  // Function to call the save translation function in editorpage component when Release button is
  // clicked
  const onSaveTranslationHandler = () => {
    editorPageRef.current.releasetextHandler();
  };

  const onAutoSaveHandler = () => {
    editorPageRef.current.autoSaveHandler();
  };

  // Funtion to make the languagepopup visible
  // when the settings icon is click event is captured
  const onClickSettingHandler = (value) => {
    // const modalDropdownBodyElement = document.getElementsByClassName('sheets__content-wrapper');
    if (value === true) {
      setSettingsClicked(!settingsClicked);
      setOpenPopup(value);
    } // languagepopup open when the settings icon is clicked
  };

  const handleDashboardPageResize = () => {
    setWindowResize(window.innerWidth);
  };

  // const queryParams = new URLSearchParams(window.location.search);
  // const PostBack = queryParams.get('code') == null;
  // To capture the authentication loading
  if (auth.isLoading) {
    return <div className="loading_ballicon"><LoadingBall /></div>;
  }

  // To capture the authentication error
  if (auth.error) {
    return (
      <div>
        Oops...
        {auth.error.message}
        {auth.signinRedirect()}
      </div>
    );
  }

  // If the authentication is success following components are rendered
  if (auth.isAuthenticated) {
    sessionStorage.setItem('userId', auth.user.profile[USER_ID]);
    window.history.pushState({}, '', '/');

    if ((auth.user.profile[USER_GROUP].length === 1
      || auth.user.profile[USER_GROUP].length === 2) && (noLocaleFlag === false)) {
      // eslint-disable-next-line no-lone-blocks
      return (
        <SitsProvider>
          <div className="app">
            <div style={{ display: openPopup === true ? '' : 'none' }}>
              <Languagepopup
                setLanguageHandler={(value) => setLanguagePopupValueHandler(value)}
                settingsIcon={settingsClicked}
                userDetails={auth.user.profile[USER_ID]}
              />
            </div>
            <BannerLanding
              heightofBanner={(bannerheight) => heightofBanner(bannerheight)}
            />
            <div className="application_container">
              <BannerMessage />
              <Header
                onClickSettingHandler={(value) => onClickSettingHandler(value)}
                isReleaseDisabled={disableRelease}
                onSaveTranslationHandler={onSaveTranslationHandler}
                onAutoSaveHandler={onAutoSaveHandler}
                autoSaveErrorFlag={autoSaveError}
                updateAutoSaveErrorFlag={updateAutoSaveErrorFlagHandler}
                isReleaseloading={loadingOfreleasebutton}
              />
              <Routes>
                <Route
                  className="toppadding"
                  path="/"
                  exact
                  element={(
                    <Dashboardpage
                      windowResize={windowResizing}
                      heightofbanner={bannerMessageheight}
                    />
                  )}
                />
                <Route
                  className="toppadding"
                  path="/Searchpage"
                  exact
                  element={<Searchpage heightofbanner={bannerMessageheight} />}
                />
                <Route
                  path="/Editorpage"
                  exact
                  element={(
                    <Editorpage
                      windowResize={windowResizing}
                      heightofbanner={bannerMessageheight}
                      ref={editorPageRef}
                      onDisableReleaseHandler={setReleaseDisableHandler}
                      updateAutoSaveErrorFlag={updateAutoSaveErrorFlagHandler}
                      loadingOfReleaseButton={updateReleasebuttonloader}
                    />
                  )}
                />
                <Route path="/Errorpage" exact element={<ErrorPage />} />
                <Route
                  path="/Dashboardpage"
                  exact
                  element={(
                    <Dashboardpage
                      windowresize={windowResizing}
                      heightofbanner={bannerMessageheight}
                    />
                  )}
                />
              </Routes>
            </div>
          </div>
        </SitsProvider>
      );
    }
    if (auth.user.profile[USER_GROUP].length === 0 || (noLocaleFlag === true)) {
      // eslint-disable-next-line no-lone-blocks
      window.history.pushState({}, '', '/#/Errorpage');
      return (

        <div className="App">
          <ErrorPage />
        </div>

      );
    }
  } else {
    <>{auth.signinRedirect()}</>; // to Capture the authentication error
  }
}
export default App;
